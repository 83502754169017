<template>
  <v-container class="tourist-scan-page" v-if="hasAccessToPage" fluid>
    <v-row justify="center">
      <v-col cols="12" lg="12" xl="10">
        <ScanInputCard :disableScanInput="disableScanInput" :caseId="caseId" @setScannedWithMobileDevice="setScannedWithMobileDevice" />
      </v-col>
    </v-row>
    <v-row class="mt-8" justify="center">
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <SubjectRelationsInfo :subjectRelations="subjectRelations" :loadProgress="loadProgress" :primarySubject="primarySubject" />
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="5" xl="5">
        <SubjectInfo
          :primarySubject="primarySubject"
          :untrusted="untrusted"
          :dbz="dbz"
          :undocumented="undocumented"
          :disableScanQrCode="disableScanQrCode"
          :linkEDCardNr="linkEDCardNr"
        />
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" xl="2">
        <TestTubeInfo :testTubeKitInfo="testTubeKitInfo" :testTubeMessage="testTubeMessage" />
      </v-col>
    </v-row>
    <v-row v-if="$store.state.storedVistorSubjects.length > 0" justify="center">
      <v-col cols="12" sm="12" lg="12" xl="10">
        <SubjectsCreatedTable />
      </v-col>
    </v-row>

    <!-- create new visitor modal -->
    <CreateVisitorSubjectModal />

    <!-- comment modal if linked within 24 hours -->
    <SubjectLinkCommentModal v-if="showCommentModal" :pay="pay" :showCommentModal="showCommentModal" />

    <!-- payment method modal -->
    <PaymentMethodModal
      v-if="showPaymentMethodModal"
      :showPaymentMethodModal="showPaymentMethodModal"
      @submitPaymentMethodParent="submitPaymentMethod"
    />

    <!-- print subject after link -->
    <PrintLinkedSubject v-if="linkedUser" id="printLinkedSubjectAuto" :linkedUser="linkedUser" :lastScannedBarcode="lastScannedBarcode" />

    <!-- link ED card -->
    <LinkEDCard v-if="showEDCardDialog" :showEDCardDialog="showEDCardDialog" :disableScanQrCode="disableScanQrCode" />
  </v-container>
  <NoAccessMessage v-else />
</template>

<script>
import SubjectInfo from '@/views/VisitorScanPage/components/SubjectInfo.vue';
import SubjectRelationsInfo from '@/views/VisitorScanPage/components/SubjectRelationsInfo.vue';
import TestTubeInfo from '@/views/VisitorScanPage/components/TestTubeInfo.vue';
import ScanInputCard from '@/views/VisitorScanPage/components/ScanInputCard.vue';
import '@/assets/scss/visitor-scan-generic.scss';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import NoAccessMessage from '@/components/generic/no-access-message.vue';
import CreateVisitorSubjectModal from '@/views/VisitorScanPage/CreateVisitorSubjectModal/CreateVisitorSubjectModal.vue';
import SubjectsCreatedTable from '@/views/VisitorScanPage/components/SubjectsCreatedTable.vue';
import PrintLinkedSubject from '@/views/VisitorScanPage/components/PrintLinkedSubject.vue';
import SubjectLinkCommentModal from '@/views/VisitorScanPage/components/SubjectLinkCommentModal.vue';
import PaymentMethodModal from '@/views/VisitorScanPage/components/PaymentMethodModal.vue';
import LinkEDCard from '@/views/VisitorScanPage/components/LinkEDCard.vue';
import moment from 'moment';

export default {
  mixins: [SharedFunctions],
  components: {
    SubjectInfo,
    SubjectRelationsInfo,
    TestTubeInfo,
    ScanInputCard,
    NoAccessMessage,
    CreateVisitorSubjectModal,
    SubjectsCreatedTable,
    PrintLinkedSubject,
    SubjectLinkCommentModal,
    PaymentMethodModal,
    LinkEDCard
  },
  data() {
    return {
      primarySubject: null,
      subjectRelations: [],
      testTubeKitInfo: null,
      testTubeMessage: null,
      intervalScanTime: 5000,
      loadProgress: null,
      scannedCaseId: null,
      disableScanInput: false,
      hasAccessToPage: false,
      storedVistorSubjects: [],
      intervalTimer: null,
      linkedUser: null,
      lastScannedBarcode: null,
      caseId: this.$route.params.caseId,
      untrusted: false,
      dbz: false,
      undocumented: false,
      tags: {
        undocumented: false,
        paymentMethod: false,
        edCardNr: null
      },
      tagsPrestine: null,
      showCommentModal: false,
      showPaymentMethodModal: false,
      withinHoursShowComment: 24,
      pay: null,
      scannedWithMobileDevice: false,
      showEDCardDialog: false,
      disableScanQrCode: false,
      linkEDCardNr: null
    };
  },
  computed: {
    dataDisplayed() {
      return this.primarySubject ? true : false;
    },
    saveButtonDisabled() {
      return this.testTubeKitInfo && this.testTubeKitInfo.data && this.testTubeKitInfo.data == 'NOT_FOUND' ? false : true;
    }
  },
  created() {
    this.hasAccessToPage = this.hasAccessToPageMixin();
    this.setSubjectsCreatedLocalStorage();
    this.tagsPrestine = JSON.stringify(this.tags);
  },
  mounted() {
    this.focusScanInput();
  },
  beforeDestroy() {
    clearTimeout(this.intervalTimer);
    this.resetAllData();
  },
  methods: {
    displaySubjectData(subjectData, scannedCaseId) {
      this.primarySubject = subjectData.filter((subject) => subject.patientCaseId == scannedCaseId)[0];
      this.subjectRelations = subjectData;
      this.scannedCaseId = scannedCaseId;
      this.linkEDCardNr = null;
      if (!this.scannedWithMobileDevice) {
        this.focusScanInput();
      } else {
        this.$store.commit('SET_SNACKBAR', { open: true, text: 'QR code successfully scanned', color: 'green' });
        this.scannedWithMobileDevice = false;
      }
      //this.initIntervalScan(true); // stopped because of performance issues
    },
    async initIntervalScan(firstTime) {
      if (!firstTime) {
        clearTimeout(this.intervalTimer);
        const subjectData = await this.$store.dispatch('getVisitorSubjectData', this.scannedCaseId);
        this.loadProgress = false;
        //display data only if scanned input matches id in subjects array, in case of slow call returns
        if (subjectData.filter((subject) => subject.patientCaseId == this.scannedCaseId)[0]) {
          this.primarySubject = subjectData.filter((subject) => subject.patientCaseId == this.scannedCaseId)[0];
          this.subjectRelations = subjectData;
          setTimeout(() => (this.loadProgress = true), 0);
        }
      } else {
        setTimeout(() => (this.loadProgress = true), 0);
      }
      this.intervalTimer = setTimeout(() => {
        if (this.dataDisplayed) this.initIntervalScan(false);
      }, this.intervalScanTime);
    },
    displayTestTubeData(data, input) {
      if (data == 'NOT_FOUND') {
        this.testTubeMessage = null;
        this.disableScanInput = true;
        this.testTubeKitInfo = { data: data, input: input };
      } else {
        this.disableScanInput = false;
        this.testTubeMessage = 'Test tube already taken, please scan another one';
        this.testTubeKitInfo = null;
      }
      if (!this.scannedWithMobileDevice) {
        this.focusScanInput();
      } else {
        if (this.testTubeMessage) {
          this.$store.commit('SET_SNACKBAR', { open: true, text: this.testTubeMessage, color: 'warning' });
        } else {
          this.$store.commit('SET_SNACKBAR', { open: true, text: 'Test tube successfully scanned', color: 'green' });
        }
        this.scannedWithMobileDevice = false;
      }
    },
    selectRelationCard(subject) {
      this.primarySubject = subject;
      this.scannedCaseId = subject.patientCaseId;
      this.testTubeMessage = null;
      this.tags.edCardNr = null;
      this.linkEDCardNr = null;
    },
    async initLinkSubjectToTestTube(pay) {
      //if first tube scanned link else check if done within 24 hours
      const testKitData = await this.$store.dispatch('getPatientTestKitInfo', this.scannedCaseId);
      if (testKitData.length == 0) {
        this.linkSubjectToTestTube(pay);
        return;
      }

      //check if already has tube scanned within 24 hours
      const lastTestKit = testKitData.pop();
      const lastTestKitDateTime = moment.utc(lastTestKit.registerDate, 'YYYY-MM-DDTHH:mm').local();
      const hoursSinceLastLinkedKit = moment().utc().diff(lastTestKitDateTime, 'hours');
      if (hoursSinceLastLinkedKit < this.withinHoursShowComment) {
        //set props
        this.pay = pay;
        this.showCommentModal = true;
      } else {
        this.linkSubjectToTestTube(pay);
      }
    },
    linkSubjectToTestTube(pay, comment = null) {
      let trusted = this.untrusted ? false : true;
      //if pay is false, clean up payment method
      if (!pay) this.updateTag('paymentMethod', false);

      let postBody = {
        testKitBarcode: this.testTubeKitInfo.input,
        pay: pay.toString(),
        trusted: trusted.toString(),
        dbz: this.dbz.toString(),
        tags: JSON.stringify(this.tags),
        comment: comment
      };

      this.$store.dispatch('linkSubjectToTestTube', { caseId: this.scannedCaseId, body: postBody }).then((data) => {
        if (data) {
          //reset modal if visible
          this.showCommentModal = false;
          //update view manually
          this.subjectRelations.forEach((subject) => {
            if (subject.patientCaseId == this.primarySubject.patientCaseId) subject.patientTestKit.push({ number: this.testTubeKitInfo.input });
          });
          //print if dashboard user has role
          if (this.$store.getters.userObject.roles.includes('mobile_scan')) {
            this.printLinkedSubjectInfo(data, this.testTubeKitInfo.input);
          } else {
            this.linkedUser = null;
          }

          //reset data
          this.resetDataAfterLinkCreated();
        }
      });
    },
    focusScanInput() {
      setTimeout(() => document.getElementById('scanTextInput').focus(), 0);
    },
    setSubjectsCreatedLocalStorage() {
      let storedVistorSubjects = JSON.parse(localStorage.getItem('dvgApp_vistorSubjects'));
      if (storedVistorSubjects) this.$store.commit('SET_STORED_VISITORS_SUBJECTS', storedVistorSubjects.slice(0, 10));
    },
    resetAllData() {
      this.subjectRelations = [];
      this.primarySubject = null;
      this.testTubeKitInfo = null;
      this.disableScanInput = false;
      this.scannedCaseId = null;
    },
    resetDataAfterLinkCreated() {
      this.testTubeKitInfo = null;
      this.disableScanInput = false;
      this.dbz = false;
      this.undocumented = false;
      this.untrusted = false;
      this.linkEDCardNr = null;
      this.tags = JSON.parse(this.tagsPrestine);
      this.focusScanInput();
    },
    printLinkedSubjectInfo(data, lastScannedBarcode) {
      this.linkedUser = data;
      this.lastScannedBarcode = lastScannedBarcode;
      this.savePrintItemLocalStorage(data, lastScannedBarcode);
      setTimeout(() => {
        this.$htmlToPaper('printLinkedSubjectAuto', null, () => {
          this.focusScanInput();
          this.linkedUser = null;
        });
      }, 400);
    },
    updateDBZ(value) {
      this.dbz = value;
    },
    updateUndocumented(value) {
      this.undocumented = value;
      this.updateTag('undocumented', value);
    },
    updateTag(key, value) {
      this.$set(this.tags, key, value);
    },
    updateUntrusted(value) {
      this.untrusted = value;
    },
    savePrintItemLocalStorage(data, lastScannedBarcode) {
      const dataToSave = {
        data,
        lastScannedBarcode
      };
      localStorage.setItem('dvgApp_lastLinkedSubject', JSON.stringify(dataToSave));
    },
    submitPaymentMethod(value) {
      this.updateTag('paymentMethod', value);
      this.initLinkSubjectToTestTube(true);
    },
    setScannedWithMobileDevice(value) {
      this.scannedWithMobileDevice = value;
    },
    linkEDCardModal() {
      this.showEDCardDialog = true;
    },
    updateEDCardId(value) {
      this.linkEDCardNr = value;
      this.updateTag('edCardNr', value);
    }
  }
};
</script>

<template>
  <v-card class="elevation-0">
    <SearchTravelerAutoCompleteInput @prefillForm="prefillFormChild" @clearParentForm="clearForm" />

    <v-btn v-if="$store.state.config.env == 'development'" class="mt-4" @click="prefillFormTest()">Prefill form</v-btn>

    <v-form v-model="formValid" ref="form-create-visitor-subject">
      <v-container style="padding-top: 40px">
        <!-- title personal info -->
        <v-row>
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <label class="primary--text title">Personal Info</label>
            <v-divider class="title-border"></v-divider>
          </v-col>
        </v-row>
        <!-- name/ last name -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.firstName"
              :rules="[v => !!v || 'This field is required']"
              class="required"
              label="First name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="formData.lastName"
              :rules="[v => !!v || 'This field is required']"
              class="required"
              label="Last name"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- dob / email -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <BirthDayPicker keyName="dateOfBirth" :valueFromParent="formData.dateOfBirth" :required="true" @updateParentValue="updateValue" />
          </v-col>
          <v-col cols="12" md="5">
            <div class="d-flex align-center">
              <v-text-field
                v-model="formData.email"
                label="Email"
                :rules="emailRules"
                type="email"
                hint="john.doe@mail.com"
                class="required"
                :disabled="noEmail"
                :style="noEmail ? 'display:none' : null"
              ></v-text-field>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-checkbox v-model="noEmail" v-on="on">
                    <template v-slot:label><span v-if="noEmail">No Email</span> </template>
                  </v-checkbox>
                </template>
                <span>No Email</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>

        <!-- phone/gender -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.phoneNumber"
              label="Phone"
              type="tel"
              :rules="[v => !!v || 'This field is required']"
              hint="eg. 593 1234"
              class="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="5">
            <v-radio-group v-model="formData.gender" class="mt-2 mb-0 required" row :rules="[v => !!v || 'This field is required']">
              <template v-slot:label>
                <div>Gender</div>
              </template>
              <v-radio label="Female" value="female"></v-radio>
              <v-radio label="Male" value="male"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!-- country / state  -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <AutoCompleteCountriesSelect
              :value="formData.countryResidency"
              label="Country of Residency"
              keyName="countryResidency"
              :required="true"
            />
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="formData.stateResidency" label="State of Residency" hint="eg. Florida"></v-text-field>
          </v-col>
        </v-row>

        <!-- title flight info -->
        <v-row>
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <label class="primary--text title">Flight Info</label>
            <v-divider class="title-border"></v-divider>
          </v-col>
        </v-row>

        <!-- arrvival/ departure -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <DatePicker
              :valueFromParent="formData.flight.travelArrivalDate"
              label="Travel Arrival Date"
              keyName="flight.travelArrivalDate"
              :required="true"
              @updateParentValue="updateValue"
            />
          </v-col>
          <v-col cols="12" md="5">
            <DatePicker
              :valueFromParent="formData.flight.travelDeparture"
              label="Travel Departure Date"
              keyName="flight.travelDeparture"
              :required="true"
              @updateParentValue="updateValue"
            />
          </v-col>
        </v-row>

        <!-- state residency / accomodation address  -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.flight.travelFlightNumber"
              :rules="[v => !!v || 'This field is required']"
              label="Flight Number"
              hint="AA1234"
              class="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="formData.flight.accommodationAddress"
              label="Host Hotel (accommodation location):"
              hint="eg. Marriot Aruba"
              :rules="[v => !!v || 'This field is required']"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- travel origin-->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.flight.travelOrigin"
              label="Travel Origin"
              hint="New York"
              :rules="[v => !!v || 'This field is required']"
              class="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="formData.flight.linkedEdcardId"
              label="Linked ED card"
              :rules="edCardInputRules"
              :class="hasLinkedEd ? 'required' : ''"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-alert type="error" v-if="formError" dense dismissible class="mt-8"> Please fill in all required fields </v-alert>
  </v-card>
</template>

<script>
import AutoCompleteCountriesSelect from '@/components/form-components/autocomplete-countries-select.vue';
import SearchTravelerAutoCompleteInput from '@/views/VisitorScanPage/components/SearchTravelerAutoCompleteInput.vue';
import DatePicker from '@/components/form-components/date-picker.vue';
import BirthDayPicker from '@/components/form-components/birthday-picker.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import moment from 'moment';

export default {
  mixins: [SharedFunctions],
  props: ['formError'],
  components: {
    AutoCompleteCountriesSelect,
    SearchTravelerAutoCompleteInput,
    DatePicker,
    BirthDayPicker
  },
  data() {
    return {
      noEmail: false,
      formValid: null,
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        gender: null,
        phoneNumber: null,
        dateOfBirth: null,
        countryResidency: null,
        stateResidency: null,
        intoQuarantineDate: null,
        flight: {
          travelOrigin: null,
          travelArrivalDate: null,
          travelDeparture: null,
          travelFlightNumber: null,
          accommodationAddress: null,
          linkedEdcardId: null
        }
      },
      formDataPrestine: null,
      isLoadingAccomodations: false,
      accomodationSuggestions: [],
      searchAccomodations: null,
      emptyItems: false,
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid', v => this.checkForDotEmail(v)]
    };
  },
  watch: {
    noEmail(n) {
      if (n) this.formData.email = this.makeUid(10) + '@dvgapp.org';
      else this.formData.email = null;
    }
  },
  created() {
    this.formData.intoQuarantineDate = moment().format('YYYY-MM-DD') + 'T01:00:00.000Z[UTC]';
    this.formDataPrestine = JSON.parse(JSON.stringify(this.formData));
  },
  methods: {
    updateValue(value, keyName) {
      if (keyName.includes('.')) {
        const keyNames = keyName.split('.');
        this.formData[keyNames[0]][keyNames[1]] = value;
      } else {
        this.formData[keyName] = value;
      }
    },
    prefillFormChild(data) {
      const itemsToPushInFlightObj = JSON.parse(JSON.stringify(this.formData.flight));
      let flightObj = {};

      for (var key of Object.keys(itemsToPushInFlightObj)) {
        if (key == 'travelArrivalDate') {
          flightObj['travelArrivalDate'] = data['travelArrival'];
          delete data['travelArrival'];
        } else if (key == 'linkedEdcardId') {
          flightObj['linkedEdcardId'] = data['edCardId'];
          delete data['edCardId'];
        } else {
          flightObj[key] = data[key];
          delete data[key];
        }
      }
      // console.log({ ...data, flight: flightObj });
      this.formData = { ...data, flight: flightObj };
    },
    clearForm() {
      this.formData = this.formDataPrestine;
    },
    prefillFormTest() {
      import('../test-data/CreateVisitorSubjectForm.test-data.json').then(data => {
        data = JSON.parse(JSON.stringify(data));

        this.formData = data;
      });
    }
  }
};
</script>

<style lang="scss">
.title-border {
  border: 1px solid #366baf !important;
}
</style>

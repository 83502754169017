<template>
  <v-row justify="center">
    <v-dialog v-model="showModal" scrollable fullscreen class="dialog-scanner">
      <v-card>
        <v-card-title class="primary white--text">
          {{ readyForTubeScan ? 'Scan tube barcode' : 'Scan QR code' }}
          <v-spacer></v-spacer>
          <v-btn icon large @click="$parent.showScannerModal = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" md="8" offset-md="2">
              <v-progress-linear indeterminate color="primary" v-if="loader"></v-progress-linear>
              <qrcode-stream @decode="onDecode" v-if="!readyForTubeScan" @init="onInit"></qrcode-stream>
              <v-zxing @decode="onDecode" @loaded="onLoaded" v-if="readyForTubeScan" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
export default {
  props: ['showModal', 'readyForTubeScan'],
  components: {
    QrcodeStream
  },
  data() {
    return {
      loader: false
    };
  },
  created() {
    this.loader = true;
  },
  methods: {
    onLoaded() {
      this.loader = false;
    },
    onDecode(decodedString) {
      this.$emit('qrScanned', decodedString);
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        this.$store.commit('SET_SNACKBAR', {
          open: true,
          text: 'Scanner not working on this device',
          color: 'error'
        });
      } finally {
        this.loader = false;
      }
    }
  }
};
</script>

<style lang="scss">
.scanner-container {
  #startButton,
  #resetButton,
  #sourceSelectPanel {
    display: none !important;
  }
  #video {
    margin: 0 auto;
    display: block;
  }
}
</style>
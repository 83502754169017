<template>

    <v-dialog
      persistent
      v-model="showCommentModal"
      width="800"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Comment
        </v-card-title>

        <v-card-text>
          <p class="mt-4 subtitle-1">
              Subject already has a linked tube to their name within the last {{$parent.withinHoursShowComment}} hours.
              Please add a comment explaining the reason why you are linking another test tube to subject's record.
          </p>

          <v-form ref="linkedSubjectCommentForm">
            <v-textarea
              outlined
              class="mt-8"
              v-model="comment"
              :counter="maxCommentChars"
              :rules="rulesComment"
              label="Comment"
              placeholder="Please type in your comment here..."
            ></v-textarea>
          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeModal()">
            Cancel
          </v-btn>
          <v-btn color="success" @click="linkSubjectToTestTubeLocal()" large class="ps-6 pe-8" :disabled="disableSaveBtn">
            <v-icon right>mdi-content-save</v-icon> Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
  export default {
    props: ['showCommentModal', 'pay'],
    data () {
      return {
        disableSaveBtn: true,
        maxCommentChars: 250,
        comment: null,
        rulesComment: [v => v == ''|| v== null || v && v.length <= this.maxCommentChars || `Max ${this.maxCommentChars} characters`],
      }
    },
    watch: {
      comment(n) {
        if(n && n.length > 0)
          this.disableSaveBtn = false;
        else
          this.disableSaveBtn = true;
      }
    },
    methods: {
        linkSubjectToTestTubeLocal() {
          if(this.$refs.linkedSubjectCommentForm.validate())
            this.$parent.linkSubjectToTestTube(this.pay, this.comment);
        },
        closeModal() {
          this.showCommentModal = false;
          this.$parent.showCommentModal = false;
        }
    }
  }
</script>
<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" large class="me-4 px-6" color="error" outlined elevation="0">Cancel</v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title> Are you sure? </v-card-title>

      <v-card-text>
        <p class="mt-4 subtitle-1">Canceling will clear the current subjects, selected subject and test tube info.</p>
        <p class="subtitle-1" v-if="disableScanInput">
          <strong> Make sure you press the save before proceeding if you want to link the test tube to the current subject. </strong>
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> No </v-btn>
        <v-btn color="error" @click="clear()"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['disableScanInput'],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    clear() {
      this.dialog = false;
      this.$emit('cancelScan');
    },
  },
};
</script>
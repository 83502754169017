<template>
    <v-card class="elevation-1 bg-whitesmoke card-empty visitor-card">

        <v-list-item one-line class="pt-1">
            <v-list-item-content>
                <!-- <div class="overline mb-4">name</div> -->
                <v-list-item-title class="subtitle-1 mb-1 primary--text font-weight-medium">TEST TUBE INFO</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        
        <v-card-text class="bg-white text-area">
            
  
            <transition name="zoom">
            <div v-if="testTubeGoodToGo" style="width:100%">
                <div class="label-value-pair">
                    <div class="label">Test Tube Number:</div>
                    <div class="value">{{testTubeKitInfo.input}}</div>
                </div>
                <div class="check-icon-wrapper icon-wrapper">
                    <v-icon color="success">mdi-check-bold</v-icon>
                </div>
                <div class="value white--text success mt-2" style="text-align:center;font-weight:500;font-size:1rem">Test tube ready to link</div>
                <v-btn class="mt-4" @click="clearTestTubeInfo()" small style="margin:0 auto;display:flex">Clear</v-btn>
            </div>
            </transition>

            <div v-if="!testTubeKitInfo" style="width:100%;display:flex">
                <div class="scan-tube-message" v-if="$parent.primarySubject" style="align-self:center;width:100%;">
                    <transition name="zoom">
                        <div v-if="testTubeMessage" class="value white--text warning" style="text-align:center">{{testTubeMessage}}</div>
                        <div v-else class="value white--text info" style="text-align:center">Scan Test Tube</div>
                    </transition>
                </div>
                <div v-if="!testTubeKitInfo && !$parent.primarySubject" class="check-icon-wrapper icon-wrapper" style="justify-self:center">
                     <v-icon>mdi-test-tube</v-icon>
                </div>
            </div>

        </v-card-text>

    </v-card>
</template>

<script>

export default {
    props: ['testTubeKitInfo', 'testTubeMessage'],
    components: {

    },
    computed: {
        testTubeGoodToGo() {
            if(this.testTubeKitInfo && this.testTubeKitInfo.data == 'NOT_FOUND' && this.testTubeKitInfo.input)
                return true;
            else
                return false;
        }
    },
    methods: {
        clearTestTubeInfo() {
            this.$parent.testTubeKitInfo = null; 
            this.$parent.disableScanInput = false;
            this.$parent.testTubeMessage = null;
            this.$parent.focusScanInput();
        }
    }
}
</script>

<style lang="scss" >

.scan-tube-message{
    font-weight: 600;
    font-size: 1.2rem;

}

</style>
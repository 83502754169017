<template>
  <v-card class="elevation-1 bg-whitesmoke card-empty visitor-card">
    <v-list-item one-line class="pt-1 pr-0">
      <v-list-item-content>
        <!-- <div class="overline mb-4">name</div> -->
        <v-list-item-title class="subtitle-1 mb-1 primary--text font-weight-medium">SELECTED SUBJECT</v-list-item-title>
      </v-list-item-content>

      <!-- overflow menu -->
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" fab>
            <v-icon large>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="$parent.linkEDCardModal()">
            <v-list-item-title><v-icon left>mdi-vector-link</v-icon>Link with ED card number</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>

    <v-card-text class="bg-white text-area">
      <transition name="wizard-back">
        <div v-if="primarySubject" style="width: 100%">
          <!-- name and last name -->
          <v-row justify="start">
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="py-0">
              <div class="label-value-pair">
                <div class="label">First Name:</div>
                <div class="value">{{ primarySubject.firstname }}</div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="py-0">
              <div class="label-value-pair">
                <div class="label">Last Name:</div>
                <div class="value">{{ primarySubject.lastname }}</div>
              </div>
            </v-col>
          </v-row>

          <!-- ED card and birthday -->
          <v-row justify="start">
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="py-0" v-if="primarySubject.edCardId">
              <div class="label-value-pair">
                <div class="label">ED Card ID:</div>
                <div class="value">{{ primarySubject.edCardId }}</div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="py-0">
              <div class="label-value-pair">
                <!-- <div class="label">Case ID:</div>
                            <div class="value">{{primarySubject.name}}</div> -->
                <div class="label">Birthday:</div>
                <div class="value">{{ formatDate(primarySubject.birthDay) }}</div>
              </div>
            </v-col>
          </v-row>

          <!-- azv number and doctor -->
          <v-row justify="start">
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="py-0" v-if="primarySubject.azvNumber">
              <div class="label-value-pair">
                <div class="label">AZV number:</div>
                <div class="value">{{ primarySubject.azvNumber }}</div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="py-0" v-if="primarySubject.doctorEntry && primarySubject.doctorEntry.name">
              <div class="label-value-pair">
                <div class="label">Doctor:</div>
                <div class="value">{{ primarySubject.doctorEntry.name }}</div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-alert v-if="linkEDCardNr" text dense class="mx-3 mt-3 full-width" dark color="success" icon="mdi-vector-link">
              ED card number to be linked: <span class="font-weight-bold">{{ linkEDCardNr }}</span>
            </v-alert>
          </v-row>

          <hr class="mb-4 mt-2 hr" />

          <!-- payment info -->
          <v-row justify="start">
            <v-col cols="12" class="py-0 mb-4">
              <div>
                <PaymentInfo :primarySubject="primarySubject" />
              </div>
              <!-- <div v-if="primarySubject.edCardId === 'MOBILE_SELF_REGISTER' || primarySubject.edCardId === 'PAPER_SELF_REGISTER'">
                          <PaymentInfo :primarySubject="primarySubject" />
                        </div> -->
            </v-col>
          </v-row>

          <hr class="mb-4 mt-2" />

          <!-- not trusten and BBZ checkboxes -->
          <div class="subtitle-2 mb-4 text-uppercase">Subject Actions</div>
          <v-row justify="start">
            <v-col cols="12" md="6" lg="4" xl="4" class="py-0">
              <div class="label-value-pair">
                <v-checkbox
                  v-model="untrusted"
                  class="py-0 my-0"
                  @change="$parent.updateUntrusted($event)"
                  :label="`Not trusted`"
                  autocomplete="false"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="4" class="py-0">
              <div class="label-value-pair">
                <v-checkbox v-model="dbz" class="py-0 my-0" @change="$parent.updateDBZ($event)" :label="`DBZ`" autocomplete="false"></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="4" class="py-0">
              <div class="label-value-pair">
                <v-checkbox
                  v-model="undocumented"
                  class="py-0 my-0"
                  @change="$parent.updateUndocumented($event)"
                  :label="`Undocumented`"
                  autocomplete="false"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </div>
      </transition>
      <transition name="wizard-down">
        <div v-if="!primarySubject" class="icon-wrapper">
          <v-icon>mdi-account</v-icon>
        </div>
      </transition>
    </v-card-text>
  </v-card>
</template>

<script>
import PaymentInfo from '@/views/VisitorScanPage/components/PaymentInfo.vue';
import moment from 'moment';

export default {
  props: ['primarySubject', 'untrusted', 'dbz', 'undocumented', 'linkEDCardNr'],
  components: {
    PaymentInfo
  },
  methods: {
    formatDate(d) {
      return d ? moment(d, 'YYYY-MM-DD').format('DD-MMM-YYYY') : 'No Data';
    }
  }
};
</script>

<style lang="scss">
.subject-image-wrapper {
  margin-top: 20px;
  .image-placeholder {
    background: whitesmoke;
    border-radius: 20px;
    max-width: 230px;
    width: 100%;
    height: 100%;
    max-height: 230px;
    margin: 0 auto;
    display: flex;
    i {
      font-size: 8rem;
      color: lightgrey;
      align-self: center;
      margin: 0 auto;
    }
  }
}
</style>

<template>
    <v-card class="elevation-1 bg-whitesmoke card-empty visitor-card mt-8">
        <v-list-item one-line class="pt-1">
            <v-list-item-content>
                <!-- <div class="overline mb-4">name</div> -->
                <v-list-item-title class="subtitle-1 mb-1 primary--text font-weight-medium">SUBJECTS CREATED, PAPER REGISTER (last 10)</v-list-item-title>
            </v-list-item-content>
        </v-list-item>     
        
        <v-card-text class="bg-white text-area" style="min-height:auto;">
            <v-simple-table style="width:100%">
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">First Name</th>
                    <th class="text-left">Last Name</th>
                    <th class="text-left">Accomodation Address</th>
                    <th class="text-left">Flight Number</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Print</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(subject, i) in $store.state.storedVistorSubjects" :key="subject.name + '-' + i" :class="subject.patientCaseId">
                        <td>{{ subject.firstName }}</td>
                        <td>{{ subject.lastName }}</td>
                        <td>{{ subject.accommodationAddress }}</td>
                        <td>{{ subject.travelFlightNumber }}</td>
                        <td>{{ subject.registrationType }}</td>
                        <td><v-btn @click="printSubject(subject)" small outlined color="primary"><v-icon left>mdi-printer</v-icon>Print</v-btn></td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <PrintSubject :formDataParent="formData" :patientCase="patientCase" :registrationType="registrationType" id="printSubjectTable"/>
    </v-card>
</template>

<script>
import PrintSubject from '@/views/VisitorScanPage/components/PrintSubject.vue'

export default {
    components: {
       PrintSubject 
    },
    data () {
        return {
            formData: null,
            patientCase: null,
            registrationType: null
        }
    },
    methods: {
        printSubject(subject) {
            this.formData = subject;
            this.patientCase = subject.patientCaseId;
            this.registrationType = subject.registrationType ? subject.registrationType : 'TRAVELER';

            setTimeout(() => {
                this.$htmlToPaper('printSubjectTable');
            }, 400);
        }
    }

}
</script>

<style>

</style>
<template>
  <v-autocomplete
    class="mt-8"
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    outlined
    clearable
    @click:clear="resetItems()"
    no-filter
    hide-details
    @change="itemSelected()"
    hide-selected
    label="Search Traveler"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title> Search traveler by first name, last name or flight number </v-list-item-title>
      </v-list-item>
    </template>

    <!-- template selected -->
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip close @click:close="resetItems()" v-bind="attr" :input-value="selected" color="primary" class="white--text" v-on="on">
        <span>{{ item.firstName }} {{ item.lastName }}</span>
      </v-chip>
    </template>

    <!-- template sugggestions -->
    <template v-slot:item="{ item }">
      <v-list-item-avatar color="grey" class="headline lighten-2 font-weight-light white--text">
        <v-icon>mdi-account</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> {{ item.firstName }} {{ item.lastName }}</v-list-item-title>
        <v-list-item-subtitle> Flight Number: {{ item.travelFlightNumber }}, From: {{ item.travelOrigin }}</v-list-item-subtitle>
        <v-list-item-subtitle>Arrival: {{ formatDate(item.travelArrival) }} / Departure: {{ formatDate(item.travelDeparture) }}</v-list-item-subtitle>
        <v-list-item-subtitle>Staying at: {{ item.accommodationAddress }}</v-list-item-subtitle>
        <v-list-item-subtitle
          >From: <span v-if="item.stateResidency">{{ item.stateResidency }} /</span> {{ item.countryResidency }}
        </v-list-item-subtitle>
        <v-list-item-subtitle></v-list-item-subtitle>
        <v-list-item-subtitle class="mt-2">
          <v-divider></v-divider>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import moment from 'moment';
export default {
  data: () => ({
    isLoading: false,
    items: [],
    model: null,
    search: null,
    tab: null,
  }),

  watch: {
    search(val, old) {
      if (val != null && old && val.length == 0 && old.length > 0) this.resetItems();

      if (!val || val.length < 3) return;

      this.isLoading = true;

      this.$store
        .dispatch('searchTravelers', { search: val })
        .then((res) => {
          this.items = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    formatDate(d) {
      return moment(d, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    resetItems() {
      this.items = [];
      this.model = null;
    },
    itemSelected() {
      this.$emit('prefillForm', this.model);
    },
  },
};
</script>
<template>
  <div>
    <v-card class="main-card" outlined>
      <v-card-title class="bg-light-primary pb-0">
        <v-row justify="start">
          <v-col md="6" cols="12">
            <v-form v-on:submit.prevent="scan(scannedInput)" autocomplete="off" class="full-width">
              <v-text-field
                ref="scanText"
                autocomplete="off"
                id="scanTextInput"
                outlined
                :hint="hint"
                @focus="readyToScan = true"
                @blur="readyToScan = false"
                :rules="[readyToScan || disableScanInput ? true : 'Place cursor inside text field before scanning']"
                persistent-hint
                background-color="white"
                v-model="scannedInput"
                v-bind:value="scannedInput"
                append-icon="mdi-qrcode-scan"
                @click:append="showScannerModal = true"
                :label="'QR-code / AZV barcode / Tube barcode'"
                single-line
                :disabled="scanInputLoading || disableScanInput"
                :loading="scanInputLoading"
              ></v-text-field>
            </v-form>
          </v-col>

          <v-col md="6" cols="12" class="mt-0 d-flex" :class="this.$vuetify.breakpoint.smAndDown ? 'justify-end' : ''">
            <div v-if="$parent.dataDisplayed">
              <v-btn large @click="$parent.showPaymentMethodModal = true" color="success" class="me-4 px-8" :disabled="$parent.saveButtonDisabled">
                <v-icon left>mdi-content-save</v-icon> Save
              </v-btn>

              <ScanInputCardCancelModal @cancelScan="cancelScan" :disableScanInput="disableScanInput" />

              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" fab>
                    <v-icon large>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item disabled>
                    <v-list-item-content>
                      <v-list-item-title><v-icon left>mdi-cash-usd-outline</v-icon>Save &amp; Pay</v-list-item-title>
                      <v-list-item-subtitle class="error--text">Please use the default save button for save and pay</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="printLastSubjectData()">
                    <v-list-item-title><v-icon left>mdi-printer</v-icon>Print Last</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              <v-btn @click="printLastSubjectData(false)" color="primary" class="mt-3"> <v-icon left>mdi-printer</v-icon> Print Last </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <ScannerModal :showModal="showScannerModal" v-if="showScannerModal" @qrScanned="qrScanned" :readyForTubeScan="readyForTubeScan" />
  </div>
</template>

<script>
import ScanInputCardCancelModal from '@/components/generic/ScanInputCardCancelModal.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import ScannerModal from '@/views/VisitorScanPage/components/ScannerModal.vue';
import { mapGetters } from 'vuex';

export default {
  mixins: [SharedFunctions],
  props: ['disableScanInput', 'caseId'],
  components: {
    ScanInputCardCancelModal,
    ScannerModal
  },
  data() {
    return {
      //prod tests: ^^00a7cfb1-4cba-498e-8adc-509a62b7d66d^, ^^697d1bfc-1e73-4581-831d-311dc2470a7e^
      //tube code: C1990000001
      scannedInput: null,
      scanInputLoading: false,
      readyToScan: false,
      testTypePrefixLength: null,
      showScannerModal: false
    };
  },
  computed: {
    ...mapGetters(['testTypes']),
    hint() {
      return this.readyToScan ? 'Ready to Scan' : null;
    },

    canSaveAndPay() {
      if (this.$store.getters.userObject.roles.includes('hoh_pay')) {
        return true;
      }
      return false;
    },
    readyForTubeScan() {
      return this.$parent.dataDisplayed;
    }
  },
  mounted() {
    this.prefillScannedInput();
    this.getTestTypes();
  },
  methods: {
    async scan(input) {
      if (!input) return;
      input = input.trim();
      this.scannedInput.trim();
      this.scanInputLoading = true;
      this.$parent.testTubeKitInfo = null;
      const codeType = this.getCodeType(input);
      let subjectData = null;
      let testTubeData = null;
      if (codeType.type == 'VISITOR_QR') {
        subjectData = await this.$store.dispatch('getVisitorSubjectData', codeType.caseId);
        this.displaySubjectDataInParent(subjectData, codeType.caseId);
      } else if (codeType.type == 'AZV_NUMBER') {
        const subjectDataAzv = await this.$store.dispatch('getSubjectDataAzvNumber', codeType.azvNumber);
        if (subjectDataAzv.length == 0) {
          this.displayScanError('No results under this AZV number');
          return;
        } else if (subjectDataAzv.length > 1) {
          this.displayScanError('Multiple accounts detected with this AZV number. Cannot continue');
          return;
        }
        subjectData = await this.$store.dispatch('getVisitorSubjectData', subjectDataAzv[0].id);
        this.displaySubjectDataInParent(subjectData, subjectDataAzv[0].id);
      } else if (codeType.type == 'TUBE_BARCODE') {
        testTubeData = await this.$store.dispatch('getTestTubeData', codeType.input);
        if (testTubeData) this.$parent.displayTestTubeData(testTubeData, codeType.input);
      }
      this.scanInputLoading = false;
      this.scannedInput = null;
    },
    cancelScan() {
      this.$parent.loadProgress = false;
      this.$parent.resetAllData();
      setTimeout(() => document.getElementById('scanTextInput').focus(), 100);
    },
    getCodeType(input) {
      this.$parent.testTubeMessage = null;
      if (input.substring(0, 2) === '^^' && input.substring(input.length - 1) === '^') {
        const trimmedInput = input.substr(2);
        const caseId = trimmedInput.substring(0, trimmedInput.length - 1);
        return { type: 'VISITOR_QR', caseId: caseId };
      } else if (this.validateAzvNumber(input.trim())) {
        return { type: 'AZV_NUMBER', azvNumber: input.trim() };
      } else if (this.validateTestTubeString(input)) {
        //only work if subject available to assign tube
        if (this.$parent.primarySubject) {
          return { type: 'TUBE_BARCODE', input: input };
        } else {
          this.$store.commit('SET_SNACKBAR', { open: true, text: 'Please scan QR code from visitor first', color: 'warning' });
          return { type: null };
        }
      } else {
        this.$parent.testTubeMessage = 'Barcode test tube or AZV number not found or invalid, please try again or scan another one';
        return { type: null };
      }
    },
    validateTestTubeString(input) {
      let validated = true;
      if (input.length !== 11 && input.length !== 12) validated = false;
      if (!this.validateTestPrefix(input)) validated = false;

      const leftOverNumbers = input.substring(this.testTypePrefixLength);
      let leftOverNumbersArray = leftOverNumbers.split('');
      for (let i = 0; i < leftOverNumbersArray.length; i++) {
        const number = leftOverNumbersArray[i];
        if (!/^\d+$/.test(number)) {
          validated = false;
          break;
        }
      }
      //if validated return true
      return validated;
    },
    prefillScannedInput() {
      if (this.caseId != undefined) {
        this.scannedInput = '^^' + this.caseId + '^';
        this.scan(this.scannedInput);
      }
    },
    printLastSubjectData() {
      const lastPrintedSubject = JSON.parse(localStorage.getItem('dvgApp_lastLinkedSubject'));
      if (lastPrintedSubject && lastPrintedSubject.data) {
        this.$parent.printLinkedSubjectInfo(lastPrintedSubject.data, lastPrintedSubject.lastScannedBarcode);
      } else {
        let payload = {
          open: true,
          text: 'No saved subjects found to print',
          color: 'warning'
        };
        this.$store.commit('SET_SNACKBAR', payload);
      }
    },
    displaySubjectDataInParent(subjectData, caseId) {
      if (subjectData) this.$parent.displaySubjectData(subjectData, caseId);
      else {
        this.scanInputLoading = false;
        this.$parent.focusScanInput();
      }
    },
    displayScanError(mesg) {
      let payload = {
        open: true,
        text: mesg,
        color: 'error'
      };
      this.$store.commit('SET_SNACKBAR', payload);

      this.scanInputLoading = false;
      this.scannedInput = null;
      this.$parent.focusScanInput();
    },
    getTestTypes() {
      if (this.testTypes.length == 0) this.$store.dispatch('getTestTypes');
    },
    validateTestPrefix(input) {
      let validated = false;
      for (let i = 0; i < this.testTypes.length; i++) {
        const testPrefix = this.testTypes[i].prefix;
        if (input.startsWith(testPrefix)) {
          this.testTypePrefixLength = testPrefix.length;
          validated = true;
          break;
        }
      }
      return validated;
    },
    qrScanned(scannedInput) {
      this.showScannerModal = false;
      this.scannedInput = scannedInput;
      this.$emit('setScannedWithMobileDevice', true);
      this.scan(scannedInput);
    }
  }
};
</script>

<style lang="scss">
.main-card {
  .small-text {
    font-size: 12px;
    top: -9px;
    padding: 0;
    position: relative;
    font-weight: 500;
    color: grey;
  }
}
</style>

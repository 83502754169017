<template>
  <v-card class="elevation-1 bg-whitesmoke card-empty visitor-card">
    <v-list-item one-line class="pt-1">
      <v-list-item-content>
        <!-- <div class="overline mb-4">name</div> -->
        <v-list-item-title class="subtitle-1 mb-1 primary--text font-weight-medium">SUBJECTS</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div class="bar" v-if="subjectRelations.length > 0">
      <div class="fill" :class="loadProgress ? 'in' : ''" :style="loadProgress ? 'transition-duration:' + $parent.intervalScanTime + 'ms' : ''"></div>
    </div>

    <v-card-text class="bg-white text-area">
      <transition name="wizard-down">
        <div v-if="subjectRelations.length > 0" style="width: 100%">
          <v-card
            v-for="subject in subjectRelations"
            :key="subject.patientCaseId"
            @click="$parent.selectRelationCard(subject)"
            outlined
            :class="subjectSelected.patientCaseId == subject.patientCaseId ? 'active-card' : ''"
            class="mb-3 relation-card pb-2 pt-1"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <div class="overline color mt-2 ms-4">
                  {{ subjectSelected.patientCaseId == subject.patientCaseId ? 'Active' : '' }}
                </div>
                <v-card-title class="subtitle-1 pt-0 title" v-text="subject.firstname + ' ' + subject.lastname"></v-card-title>
                <v-card-subtitle v-text="subject.name" class="px-4 py-0 case-number"></v-card-subtitle>
                <div class="px-2 mb-2 text-test-tube">
                  <v-icon>mdi-test-tube</v-icon>
                  <span v-if="subject.patientTestKit.length > 0">{{ subject.patientTestKit[subject.patientTestKit.length - 1].number }}</span>
                  <span v-else>None</span>
                </div>
              </div>

              <v-avatar class="ma-2 check-icon-wrapper" size="080" rounded>
                <v-icon v-if="subject.patientTestKit.length == 0">mdi-qrcode-scan</v-icon>
                <transition name="zoom">
                  <v-icon color="success" v-if="subject.patientTestKit.length > 0">mdi-check-bold</v-icon>
                </transition>
              </v-avatar>
            </div>
          </v-card>
        </div>
      </transition>
      <transition name="wizard-down">
        <div v-if="subjectRelations.length == 0" class="check-icon-wrapper icon-wrapper">
          <v-icon>mdi-account-multiple</v-icon>
        </div>
      </transition>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['subjectRelations', 'loadProgress', 'primarySubject'],
  components: {},
  data() {
    return {};
  },
  computed: {
    subjectSelected() {
      if (this.primarySubject) return this.primarySubject;
      else {
        return {
          patientCaseId: null,
        };
      }
    },
  },
};
</script>

<style lang="scss" >
.visitor-card {
  .overline.color {
    color: #2196f3;
    font-weight: 600;
  }
  .check-icon-wrapper {
    background: whitesmoke;
    i {
      font-size: 2.5rem;
      color: lightgrey;
    }
  }

  .bar {
    height: 3px;
    width: 100%;
    .fill {
      width: 0%;
      transition: none;
    }
    .in {
      transition-property: width;
      transition-timing-function: linear;
      height: 100%;
      background-color: lighten(#366baf, 35%);
      width: 100%;
    }
  }
  .relation-card {
    border-radius: 10px !important;
    .title {
      font-size: 1.1rem !important;
      margin-bottom: 2px;
    }
    .case-number {
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 4px;
    }
    &:hover {
      //background: rgba(#2196f3, .1) !important;
      box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2) !important;
    }
  }
  .overline {
    font-size: 14px !important;
  }
  .active-card {
    border: 3px solid #2196f3 !important;
  }
  .text-test-tube {
    i {
      font-size: 22px;
    }
    font-size: 1rem;
  }
}
</style>

<template>
  <v-dialog v-model="showEDCardDialog" width="600" scrollable persistent>
    <v-card class="edcard-form">
      <v-card-title class="headline primary white--text" primary-title>Add ED number to subject</v-card-title>
      <v-card-text v-if="!edCardVerified">
        <img :src="require('@/assets/images/generic/ed-card-illustration-grey.png')" alt="" srcset="" />
        <v-form v-on:submit.prevent="scanQR(scannedQR)" style="max-width: 450px; margin: 0 auto" ref="submitEdCardForm">
          <!-- ED card passenger -->
          <p>Please scan the ED card number of the passenger</p>
          <v-textarea
            label="QR-code"
            type="text"
            append-icon="mdi-qrcode-scan"
            outlined
            ref="scanQRCode"
            autocomplete="off"
            id="scanQRCode"
            :hint="hint"
            @focus="readyToScan = true"
            @blur="readyToScan = false"
            :rules="[readyToScan || disableScanInput ? true : 'Place cursor inside text field before scanning']"
            persistent-hint
            background-color="white"
            v-model="scannedQR"
            v-bind:value="edCardNr"
            @click:append="showScannerModal = true"
            rows="1"
            row-height="15"
            no-resize
            :disabled="scanInputLoading || disableScanInput"
            :loading="scanInputLoading"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="scanQR(scannedQR)"
          ></v-textarea>
        </v-form>
        <v-alert type="error" dense text v-if="parseError" style="max-width: 450px; margin: 0 auto">Error parsing input</v-alert>
      </v-card-text>

      <v-card-text class="my-4" v-else>
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <h3 class="mb-1">
              <v-avatar class="avatar-wrapper" size="60">
                <v-icon class="ma-0 pa-0" color="green" large>mdi-check-circle</v-icon>
              </v-avatar>
              Qualified to board
            </h3>
            <v-simple-table class="ma-0 pa-0">
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, i) in displayData" :key="i + '-travel-data'">
                    <template>
                      <td class="caption">{{ displayDataHeader[i] }}</td>
                      <td class="font-weight-medium">{{ item }}</td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()"> Cancel </v-btn>
        <v-btn color="success" @click="linkEdCardInfo()" type="submit" class="mx-4" :disabled="disabledSubmit"> Add </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['showEDCardDialog'],
  data() {
    return {
      scannedQR: null,
      scanInputLoading: false,
      readyToScan: false,
      disableScanInput: false,
      edCardNr: '',
      displayDataHeader: ['Passenger name', 'Arrival', 'Travel flight number', 'ED card number', 'Date of birth', 'Health state'],
      displayData: [],
      cnt: 0,
      disabledSubmit: true,
      parseError: false,
      edCardVerified: false,
      healthState: ''
    };
  },
  computed: {
    hint() {
      return this.readyToScan ? 'Ready to Scan' : null;
    }
  },
  mounted() {
    if (this.showEDCardDialog) this.focusScanInput();
  },
  methods: {
    closeModal() {
      this.$parent.showEDCardDialog = false;
      this.resetForm();
    },
    resetForm() {
      this.scannedQR = null;
      this.edCardNr = '';
      this.displayData = [];
      this.disabledSubmit = true;
      this.disableScanInput = false;
      this.scanInputLoading = false;
      this.cnt = 0;
    },
    verifyEdCardInfo() {
      if (this.edCardNr == null || this.edCardNr.trim().length == 0) return;
      // this.edCardNr = '300100';
      this.$store.dispatch('verifyEdCardData', this.edCardNr).then(data => {
        if (data) {
          this.edCardVerified = true;
          this.disabledSubmit = false;
          this.displayData.push(data.dateOfBirth);
          this.displayData.push(data.healthState);
        } else {
          this.$store.dispatch('showError', 'Error loading ED card data');
          this.disableScanInput = false;
          this.resetForm();
          this.focusScanInput();
        }
      });

      return false;
    },
    linkEdCardInfo() {
      this.$parent.updateEDCardId(this.edCardNr);
      this.closeModal();
    },

    async scanQR(input) {
      if (input) {
        input = input.trim();
        this.scannedQR.trim();
        // if (this.$parent.tags.edCardNr != null) this.$parent.updateEDCardId(null);

        this.parseQRData(input);
        if (this.displayData.length == 4) {
          this.edCardNr = this.displayData[3];
          this.disableScanInput = true;
          this.parseError = false;
          this.verifyEdCardInfo();
        }
      } else {
        this.parseError = true;
        this.disabledSubmit = true;
      }
    },
    parseQRData(input) {
      if (!input) return;
      let data = '';

      if (this.cnt <= 3) {
        if (this.cnt == 0) {
          data = input;
        } else {
          let parsedInput = input;

          for (let i = 0; i < this.displayData.length; i++) {
            const previousData = this.displayData[i];
            parsedInput = parsedInput.replace(previousData, '');
          }
          data = parsedInput;
        }

        this.displayData.push(data);
        this.cnt++;
      }
    },
    focusScanInput() {
      if (document.getElementById('scanQRCode') != undefined) {
        setTimeout(() => document.getElementById('scanQRCode').focus(), 0);
      }
    }
  }
};
</script>

<style lang="scss">
.mdi-qrcode-scan {
  color: #4a6ca5;
}

.edcard-form {
  .avatar-wrapper {
    background: white;
    border-radius: 100px !important;
    i {
      /*font-size: 2rem;*/
    }
  }
}
</style>

<template>
    <div class="payment-info-wrapper">
        <div class="subtitle-2 mb-2 text-uppercase">Payment Info</div>
        <template v-if="loading">
        <v-skeleton-loader
            v-for="(page,i) in 5" :key="'skeletonloader-' + i"
            type="list-item"
            ref="skeleton"
            style="height:40px"
            class="mx-auto my-0 py-0"
        ></v-skeleton-loader>
        </template>
        <template v-if="!noResults && !loading">
            <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Full Name and Ins dates</th>
                    <th class="text-left">Age</th>
                    <th class="text-left">Country</th>
                </tr>
                </thead>
                <tbody v-if="searchResults">
                <tr v-for="subject in searchResults" :key="subject.id">
                    <td><b>{{ subject.name }}</b><br/><i>{{ subject.startDate }}</i> -> <i>{{ subject.endDate }}</i></td>
                    <td>{{ subject.age }}</td>
                    <td>{{ subject.country }}</td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>
        </template>
        <div class="subtitle-1" v-if="noResults">
            No Results
        </div>
    </div>
</template>

<script>

export default {
    props: ['primarySubject'],
    data() {
        return {
            searchSubjectInput: null,
            loading: false,
            searchResults: [],
            noResults: false
        }
    },
    computed: {
        fullName() {
            //console.log(this.primarySubject);
            if(this.primarySubject && this.primarySubject.firstname && this.primarySubject.lastname)
                return this.primarySubject.firstname.trim() +' '+ this.primarySubject.lastname.trim();
            else
                return '';
        }
    },
    watch: {
        fullName(n,o) {
            console.log(n, o);
            if(n && n != o)
                this.getPaymentInfo();
        }
    },
    created() {
        this.getPaymentInfo();
    },
    methods: {
        getPaymentInfo() {
            this.loading = true;
            this.noResults = false;
            this.$store.dispatch('searchSubjectPaymentInfo', {search: this.fullName}).then((data) => {
                this.displaySearchData(data);
                this.loading = false;
            }).catch(()=> {
                this.loading = false;
            })
        },
        clearPaymentInfo() {
            this.searchResults = [];
        },
        displaySearchData(data) {
            if(data.length == 0)
                this.noResults = true;
            this.searchResults = data;
        }
    }
}
</script>

<style lang="scss">
.payment-info-wrapper{
    table{
        background: #f5f5f5;
    }
}

</style>

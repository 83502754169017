<template>
  <div class="text-center">
    <v-dialog v-model="showDialog" width="800" scrollable persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark fab bottom right color="primary" fixed large v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card id="printMe">
        <v-card-title class="headline primary white--text" primary-title>Create Subject</v-card-title>

        <!-- Registration forms -->
        <v-card-text v-if="registrationType">
          <CreateVisitorSubjectForm v-if="showDialog && registrationType == 'TRAVELER'" ref="visitorCreationFormComponent" :formError="formError" />
          <CreateVisitorSubjectLocalForm v-if="showDialog && registrationType == 'LOCAL'" ref="visitorCreationFormComponent" :formError="formError" />
        </v-card-text>

        <!-- select registration forms -->
        <v-card-text v-else>
          <h2 class="text-center my-4 title">Select registration type</h2>
          <v-row justify="center" style="min-height: 200px">
            <v-col class="py-0 pe-2 text-right text-center" cols="12" md="6">
              <v-btn x-large color="primary" dark outlined @click="registrationType = 'TRAVELER'" style="min-width: 300px">
                <v-icon left>mdi-airplane</v-icon> Traveler Registration
              </v-btn>
              <div class="title my-4">OR</div>
              <v-btn x-large color="primary" dark outlined @click="registrationType = 'LOCAL'">
                <v-icon left>mdi-map-marker</v-icon> Local Registration
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <!-- modal buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeForm()" :disabled="loadingSubmission"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="createNewSubject()"
            v-if="registrationType"
            elevation="0"
            :loading="loadingSubmission"
            :disabled="loadingSubmission"
          >
            Save
          </v-btn>
        </v-card-actions>
        <PrintSubject :formDataParent="formData" :patientCase="patientCase" id="printSubjectAuto" :registrationType="registrationType" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CreateVisitorSubjectForm from '@/views/VisitorScanPage/CreateVisitorSubjectModal/components/CreateVisitorSubjectForm.vue';
import CreateVisitorSubjectLocalForm from '@/views/VisitorScanPage/CreateVisitorSubjectModal/components/CreateVisitorSubjectLocalForm.vue';
import PrintSubject from '@/views/VisitorScanPage/components/PrintSubject.vue';
import moment from 'moment';

export default {
  data() {
    return {
      showDialog: false,
      formError: false,
      patientCase: null,
      formData: null,
      loadingSubmission: null,
      registrationType: null, //LOCAL or TRAVELER
    };
  },
  components: {
    CreateVisitorSubjectForm,
    CreateVisitorSubjectLocalForm,
    PrintSubject,
  },
  watch: {
    showDialog(n) {
      if (n === false) this.registrationType = null;
    },
  },
  methods: {
    createNewSubject() {
      if (this.$refs.visitorCreationFormComponent.$refs['form-create-visitor-subject'].validate()) {
        this.formError = false;
        this.formData = this.$refs.visitorCreationFormComponent.formData;

        //choos action based on form type
        let action = this.registrationType == 'TRAVELER' ? 'createNewTravelerSubjectPaper' : 'createNewLocalSubjectPaper';

        //clean up
        let dataToPost = JSON.parse(JSON.stringify(this.formData));
        if (this.registrationType == 'LOCAL') {
          if (!dataToPost.azvNumber) delete dataToPost.azvNumber;
          if (!dataToPost.doctorEntry.id) delete dataToPost.doctorEntry;
          else {
            dataToPost.doctorName = this.getDoctorNameFromId(dataToPost.doctorEntry.id);
            //set doctor name on formdata so its send in the prop to the print component
            this.formData.doctorName = dataToPost.doctorName;
          }
          //registration date
          dataToPost.intoQuarantineDate = moment().local().format('YYYY-MM-DD[T]HH:mm:ss.000') + 'Z';
        }

        //register new user, action based on form type selection
        this.loadingSubmission = true;
        this.$store
          .dispatch(action, dataToPost)
          .then((data) => {
            if (data) {
              this.patientCase = data.patientCaseId;
              this.print();
              this.saveSubjectToLocalStorage(dataToPost, this.patientCase, this.registrationType);
              this.$parent.setSubjectsCreatedLocalStorage();
            }
          })
          .finally(() => {
            this.loadingSubmission = false;
          });
      } else {
        this.formError = true;
      }
    },
    closeForm() {
      this.showDialog = false;
      this.formError = false;
    },
    saveSubjectToLocalStorage(newSubject, patientCaseId, registrationType) {
      newSubject.patientCaseId = patientCaseId;
      newSubject.registrationType = registrationType;

      let vistorSubjects = [];
      let storedVistorSubjects = JSON.parse(localStorage.getItem('dvgApp_vistorSubjects'));
      if (storedVistorSubjects) {
        vistorSubjects = storedVistorSubjects;
        vistorSubjects.unshift(newSubject);
      } else {
        vistorSubjects[0] = newSubject;
      }

      localStorage.setItem('dvgApp_vistorSubjects', JSON.stringify(vistorSubjects.slice(0, 10)));
    },
    print() {
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'Opening print page' });
      setTimeout(() => {
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        this.$htmlToPaper('printSubjectAuto', null, () => {
          let payload = {
            open: true,
            text: 'Subject created successfully',
            color: 'success',
          };
          this.$store.commit('SET_SNACKBAR', payload);
          this.$parent.focusScanInput();
          this.showDialog = false;
        });
      }, 400);
    },
    getDoctorNameFromId(id) {
      if (this.$store.state.doctorOptions.length > 0) {
        let selectedDoctor = this.$store.state.doctorOptions.filter((doctor) => id == doctor.value);
        return selectedDoctor[0].text;
      }
    },
  },
};
</script>

<style>
</style>
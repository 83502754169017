<template>
  <v-card class="elevation-0">
    <v-form v-model="formValid" ref="form-create-visitor-subject">
      <v-alert type="error" v-if="formError" dense dismissible class="mt-8"> Please fill in all required fields </v-alert>
      <v-container style="padding-top: 40px">
        <!-- name/ last name -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.firstName"
              :rules="[(v) => !!v || 'This field is required']"
              class="required"
              label="First name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="formData.lastName"
              :rules="[(v) => !!v || 'This field is required']"
              class="required"
              label="Last name"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- dob / email -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <BirthDayPicker keyName="dateOfBirth" :valueFromParent="formData.dateOfBirth" :required="true" @updateParentValue="updateValue" />
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              :disabled="noEmail"
              :style="noEmail ? 'display:none' : null"
              v-model="formData.email"
              label="Email"
              :rules="emailRules"
              type="email"
              hint="john.doe@mail.com"
              class="required"
            ></v-text-field>
            <v-checkbox v-model="noEmail" label="No email"></v-checkbox>
          </v-col>
        </v-row>

        <!-- gender -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <v-radio-group v-model="formData.gender" class="mt-0 mb-0 required" row :rules="[(v) => !!v || 'This field is required']">
              <template v-slot:label>
                <div>Gender</div>
              </template>
              <v-radio label="Female" value="female"></v-radio>
              <v-radio label="Male" value="male"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!-- phone /address  -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.phoneNumber"
              label="Phone"
              type="tel"
              :rules="[(v) => !!v || 'This field is required']"
              hint="eg. 593 1234"
              class="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="formData.accommodationAddress"
              label="Full Address"
              hint="eg. Savaneta 100A"
              :rules="[(v) => !!v || 'This field is required']"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- azv / doctor  -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.azvNumber"
              label="AZV Number"
              :loading="checkingAzvNumber"
              :disabled="checkingAzvNumber"
              @input="validateAzvNumberFunctionLocal($event)"
              type="text"
              :error-messages="errorAzvAreadyInUse"
              :rules="[(v) => validateAzvNumberLocal || !v || 'AZV number not valid']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-autocomplete
              clearable
              :disabled="doctorOptions.length == 0"
              :loading="doctorOptions.length == 0"
              v-model="formData.doctorEntry.id"
              data-lpignore="true"
              :items="doctorOptions"
              label="Doctor"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="userHasVaccineRoles">
          <v-col cols="12" md="5" offset-md="1">
            <v-checkbox v-model="formData.travelOrigin" label="Request Vaccine" disabled></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import BirthDayPicker from '@/components/form-components/birthday-picker.vue';
import moment from 'moment';
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  props: ['formDataParent', 'formError'],
  components: {
    BirthDayPicker,
  },
  data() {
    return {
      noEmail: false,
      userHasVaccineRoles: false,
      formValid: null,
      validateAzvNumberLocal: true,
      azvNumberCache: null,
      checkingAzvNumber: false,
      errorAzvAreadyInUse: null,
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        gender: null,
        phoneNumber: null,
        dateOfBirth: null,
        accommodationAddress: null,
        intoQuarantineDate: null, //registration date
        azvNumber: null,
        travelOrigin: null,
        doctorEntry: {
          id: null,
        },
      },
      isLoadingAccomodations: false,
      accomodationSuggestions: [],
      searchAccomodations: null,
      emptyItems: false,
      emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid', (v) => this.checkForDotEmail(v)],
    };
  },
  computed: {
    doctorOptions() {
      return this.$store.state.doctorOptions;
    },
    userRoles() {
      return this.$store.state.userObject.roles;
    },
  },
  watch: {
    noEmail(n) {
      if (n) this.formData.email = this.makeUid(10) + '@dvgapp.org';
      else this.formData.email = null;
    },
  },
  created() {
    //set registration date
    this.formData.intoQuarantineDate = moment().format('YYYY-MM-DD') + 'T01:00:00.000Z[UTC]';
    //get doctors
    if (this.doctorOptions.length == 0) this.$store.dispatch('getDoctorOptions');
    //cache azv number for edit validation
    if (this.formData.azvCardNumber) this.azvNumberCache = this.formData.azvCardNumber;
    //temp scan if user has vaccine role
    if (this.userRoles.includes('vaccine-mfa-admin') || this.userRoles.includes('vaccine-inventory')) {
      this.userHasVaccineRoles = true;
      this.formData.travelOrigin = 'MFA_VACCINE_REQUEST';
    } else {
      this.userHasVaccineRoles = false;
      delete this.formData.travelOrigin;
    }
  },
  methods: {
    updateValue(value, keyName) {
      if (this.formData[keyName] === undefined) {
        this.$set(this.formData, keyName, null);
      }
      this.formData[keyName] = value;
    },
    prefillFormChild(data) {
      if (!data) return;
      delete data.rank;
      this.formData = data;
    },
    validateAzvNumberFunctionLocal(value) {
      if (this.validateAzvNumber(value)) {
        this.validateAzvNumberLocal = true;
        this.checkAzvExists(value);
      } else if (value == '') {
        this.validateAzvNumberLocal = true;
        this.errorAzvAreadyInUse = null;
      } else this.validateAzvNumberLocal = false;
    },
    checkAzvExists(value) {
      if (this.azvNumberCache != value) {
        this.checkingAzvNumber = true;
        if (this.newCase) this.$parent.$parent.$parent.$parent.checkingAzvNumber = true;
        this.azvNumberAlreadyRegistered(value)
          .then((result) => {
            if (result) {
              this.errorAzvAreadyInUse = 'AZV number already in use';
            } else this.errorAzvAreadyInUse = null;
          })
          .finally(() => {
            this.checkingAzvNumber = false;
            if (this.newCase) this.$parent.$parent.$parent.$parent.checkingAzvNumber = false;
          });
      } else this.errorAzvAreadyInUse = null;
    },
  },
};
</script>

<style lang="scss">
.title-border {
  border: 1px solid #366baf !important;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"mt-8",attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"outlined":"","clearable":"","no-filter":"","hide-details":"","hide-selected":"","label":"Search Traveler"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"click:clear":function($event){return _vm.resetItems()},"change":function($event){return _vm.itemSelected()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search traveler by first name, last name or flight number ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"close":"","input-value":selected,"color":"primary"},on:{"click:close":function($event){return _vm.resetItems()}}},'v-chip',attr,false),on),[_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline lighten-2 font-weight-light white--text",attrs:{"color":"grey"}},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName))]),_c('v-list-item-subtitle',[_vm._v(" Flight Number: "+_vm._s(item.travelFlightNumber)+", From: "+_vm._s(item.travelOrigin))]),_c('v-list-item-subtitle',[_vm._v("Arrival: "+_vm._s(_vm.formatDate(item.travelArrival))+" / Departure: "+_vm._s(_vm.formatDate(item.travelDeparture)))]),_c('v-list-item-subtitle',[_vm._v("Staying at: "+_vm._s(item.accommodationAddress))]),_c('v-list-item-subtitle',[_vm._v("From: "),(item.stateResidency)?_c('span',[_vm._v(_vm._s(item.stateResidency)+" /")]):_vm._e(),_vm._v(" "+_vm._s(item.countryResidency)+" ")]),_c('v-list-item-subtitle'),_c('v-list-item-subtitle',{staticClass:"mt-2"},[_c('v-divider')],1)],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }